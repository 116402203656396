#About .about-section-content-row img {
    max-width: 210px;
}

@media screen and (max-width: 479px) {
    #About .grid.about-section-content-row {
        grid-auto-flow: column;
    }
    #About .about-section-content-row img {
        width: 90px;
        min-width: 10px;
    }
}
