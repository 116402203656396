.wrapper.wallet-column.right {
    margin-left: -2em;
}
.watts-claimed {
    display: flex;
    align-items: center;
}
.watts-claimed .text {
    margin-left: 7px;
}
@media(max-width: 992px) {
    .wrapper.wallet-column.right {
        margin-left: inherit;
    }
    .watts-claimed {
        flex-direction: column;
    }
    .wallet-column .connected-tag {
        margin-right: auto;
    }
    .watts-claimed .text {
        margin-top: 3px;
        word-break: break-all;
        margin-left: 0;
    }
}
